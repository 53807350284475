
import { defineComponent, reactive, ref, computed } from 'vue'
import AuthSubmitButton from '@/components/particles/AuthLayout/AuthSubmitButton.vue'
import PasswordRequirements from '@/components/particles/forms/ForgetPasswordForm/PasswordRequirements.vue'
import Logo from '@/components/particles/common/Logo.vue'
import {
  useField,
  useForm,
  useIsFormValid,
  useIsSubmitting,
} from 'vee-validate'
import { useI18n } from 'vue-i18n'
import useStore from '@/store'
import { AUTH_ACTION_TYPES } from '@/store/auth/actions'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    AuthSubmitButton,
    Logo,
    PasswordRequirements,
  },

  emits: ['update-component'],
  setup() {
    const { t } = useI18n()
    const store = useStore()
    const router = useRouter()
    const { meta, handleSubmit } = useForm({
      validationSchema: {
        newPassword: 'required',
        passwordAgain: 'required',
      },
    })

    const form = reactive({
      newPassword: useField('newPassword', '', {
        label: t('FORGET_PASSWORD_NEW_PASSWORD_OLD_LABEL'),
      }),
      passwordAgain: useField('passwordAgain', '', {
        label: t('FORGET_PASSWORD_NEW_PASSWORD_NEW_LABEL'),
      }),
    })

    const userId = computed(() => store.state.auth.userInfo.user_id)
    const smsCode = computed(() => store.state.auth.smsCode)

    const onSubmit = handleSubmit(async ({ passwordAgain }) => {
      try {
        await store.dispatch(AUTH_ACTION_TYPES.CREATE_PASSWORD, {
          user: userId.value,
          new_password: passwordAgain,
          verification_code: smsCode.value,
        })
        router.push({ name: 'AuthorizationView' })
      } catch (error: any) {
        if (error?.request?.status === 400) {
          //error
        }
        if (error instanceof Error) {
          //error
        }
      }
    })

    return {
      form,
      onSubmit,
      meta,
      isValid: useIsFormValid(),
      isSubmitting: useIsSubmitting(),
      isPasswordValid: ref(false),
    }
  },
})
